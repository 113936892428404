import moment from 'moment'
import React, { ReactElement } from 'react'
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos'
import { navigate } from 'gatsby'
import { withWidth, isWidthUp, WithWidthProps, makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { Paystub } from '../../models/payment'
import Button from '../unstyled-button.component'

type PaystubItemProps = {
  data: Paystub
  date: string
  isSelected: boolean
  setSelected(date: string): void
}&WithWidthProps
const useStyles = makeStyles((theme) => ({
  selectedPaystub: {
    border: '#DDD 2px solid',
  },
  itemWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    borderRadius: '5px',
    cursor: 'pointer',
    '&:nth-child(odd)': {
      background: theme.palette.action.hover,
    },
    '&:hover': {
      border: '#DDD 2px solid',
    },
  },
  itemRightWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  moneyText: {
    color: theme.palette.primary.main,
    paddingRight: theme.spacing(1),
  },
}))
function PaystubItem({ date, data, isSelected, setSelected, width }: PaystubItemProps): ReactElement {
  const formattedDate = moment(date, 'M-D-YYYY')
  const classes = useStyles()
  const handleClick = (): void => {
    if (isWidthUp('md', width)) setSelected(date)
    else navigate(`/payments/${date}`)
  }
  return (
    <Button className={clsx([classes.itemWrapper, { [classes.selectedPaystub]: isSelected }])} onClick={handleClick}>
      <Typography>{formattedDate.format('MMM Do YYYY')}</Typography>
      <div className={classes.itemRightWrapper}>
        <Typography className={classes.moneyText}>{`$${data.total.toFixed(2)}`}</Typography>
        <ArrowForwardIcon color="primary" fontSize="small" />
      </div>
    </Button>
  )
}

export default withWidth()(PaystubItem)
