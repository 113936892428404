import React, { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

type ButtonProps = {
  children: React.ReactNode
}&React.HTMLAttributes<HTMLButtonElement>
const useStyles = makeStyles(() => ({
  buttonStyle: {
    backgroundColor: '#FFF',
    border: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
}))
export default function Button({ children, className, ...props }: ButtonProps): ReactElement {
  const classes = useStyles()

  return (
    <button {...props} className={clsx([classes.buttonStyle, className])} type="button">{children}</button>
  )
}
