import React, { ReactElement, useContext } from 'react'
import { makeStyles, Theme, Typography } from '@material-ui/core'
import { GlobalContext } from '../contexts/global.store'

const useStyles = makeStyles((theme: Theme) => ({
  nameAndEmailWrapper: {
    marginTop: theme.spacing(2),
  },
  text: {
    color: theme.palette.grey[600],
  },
}))
export default function NameAndEmailComponent(): ReactElement {
  const classes = useStyles()
  const [globalState] = useContext(GlobalContext)
  const { currentUser } = globalState
  return (
    <>
      {currentUser && (
        <div className={classes.nameAndEmailWrapper}>
          <Typography className={classes.text}>{currentUser.name}</Typography>
          <Typography className={classes.text}>{currentUser.email}</Typography>
        </div>
      )}
    </>
  )
}
