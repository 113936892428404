import React, { ReactElement } from 'react'

import { AppComponent } from '../components/app/app.component'
import HeadComponent from '../components/head.component'
import { PaymentsComponent } from '../components/payments.component'
import LoginGuardComponent from '../components/app/login.gaurd.component'

export default function Home(): ReactElement {
  return (
    <AppComponent>
      <LoginGuardComponent>
        <HeadComponent title="View payments from anywhere" />
        <PaymentsComponent />
      </LoginGuardComponent>
    </AppComponent>
  )
}
