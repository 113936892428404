import React, { ReactElement, useEffect, useState, useContext } from 'react'
import { makeStyles, Typography, useMediaQuery, Theme, CircularProgress } from '@material-ui/core'
import clsx from 'clsx'
import { getLatestPaymentMonth, getPayments } from '../services/payment.service'
import { UserPayments } from '../models/payment'
import PaystubItem from './payments/paystub-item.component'
import Paystub from './payments/paystub-detail/paystub-detail.component'
import PaymentFilter from './payments/payment-filter.component'
import NameAndEmail from './name-and-email.component'
import { MessageContext } from '../contexts/message.store'
import { getDefaultFilter } from '../utils/time'

const useStyles = makeStyles((theme) => ({
  listProgressHidden: {
    display: 'none',
  },
  listProgress: {
    margin: '0 auto',
  },
  paymentListSection: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      overflow: 'auto',
      paddingRight: theme.spacing(4),
    },
  },
  paymentTitle: {
    marginBottom: theme.spacing(8),
  },
  paystubSection: {
    flex: 1,
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  paymentsList: {
    display: 'flex',
    flexDirection: 'column',
  },
  paymentsWrapper: {
    display: 'flex',
    height: '100%',
  },
}))

export function PaymentsComponent(): ReactElement {
  const classes = useStyles()
  const notMobileScreens = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))
  const [, messageDispatch] = useContext(MessageContext)
  const [isListLoading, setListLoading] = useState(false)

  const [payments, setPayments] = useState<UserPayments>({})
  const [selectedPaystub, setSelectedPaystub] = useState('')
  const { month: m, year: y } = getDefaultFilter()
  const [paymentFilter, setPaymentFilter] = useState({ month: m, year: y })
  const getLatestMonth = async (): Promise<void> => {
    try {
      const payment_month = await getLatestPaymentMonth()
      setPaymentFilter({ ...paymentFilter, month: payment_month })
    } catch (err) {
      messageDispatch({ type: 'ERROR', message: `${err.status}` })
    }
  }
  useEffect(() => {
    getLatestMonth()
  }, [])
  const getPaymentsFromApi = async (): Promise<void> => {
    setListLoading(true)
    try {
      const data = await getPayments(paymentFilter)
      setPayments(data)
      setSelectedPaystub(Object.keys(data)[0])
    } catch (err) {
      messageDispatch({ type: 'ERROR', message: `${err.status}` })
    }
    setListLoading(false)
  }
  useEffect(() => {
    setPayments({})
    getPaymentsFromApi()
  }, [paymentFilter])
  return (
    <div className={classes.paymentsWrapper}>
      <section className={classes.paymentListSection}>
        <Typography variant="h5" component="h5" className={classes.paymentTitle}>
          Payments
          <NameAndEmail />
        </Typography>
        <PaymentFilter
          setSelectedMonth={(month): void => { setPaymentFilter({ ...paymentFilter, month }) }}
          setSelectedYear={(year): void => { setPaymentFilter({ ...paymentFilter, year }) }}
          selectedMonth={paymentFilter.month}
          selectedYear={paymentFilter.year}
        />
        <CircularProgress
          size={60}
          className={clsx(classes.listProgress, { [classes.listProgressHidden]: !isListLoading })}
        />
        <div className={classes.paymentsList}>
          {
            Object.keys(payments).length == 0 && !isListLoading
              ? (<Typography>No payments at the selected time</Typography>)
              : Object.keys(payments).map((day) => (
                <PaystubItem data={payments[day]} date={day} key={day} setSelected={(date): void => setSelectedPaystub(date.toString())} isSelected={day === selectedPaystub && notMobileScreens} />
              ))
          }
        </div>
      </section>
      <section className={classes.paystubSection}>
        <Paystub date={selectedPaystub} />
      </section>
    </div>
  )
}
